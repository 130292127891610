import { isRouteErrorResponse, useNavigate, useRouteError } from "@remix-run/react";
import { Button } from "~/components/Button";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

export function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();
  captureRemixErrorBoundaryError(error);
  if (isRouteErrorResponse(error)) {
    return (
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-primary">{error.status}</p>
          <h1 className="mt-4 font-bold tracking-tight text-gray-100 text-2xl">Unexpected Error</h1>
          <p className="mt-6 text-base leading-7 text-gray-300">{error.data ?? error.statusText}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </div>
        </div>
      </main>
    );
  } else if (error instanceof Error) {
    return (
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-primary">500</p>
          <h1 className="mt-4 font-bold tracking-tight text-gray-100 text-2xl">Unexpected Error</h1>
          <p className="mt-6 text-base leading-7 text-gray-300">
            Sorry, we will fix this as soon as possible.
          </p>
          <p className="mt-1 text-sm leading-3 text-gray-500">{error.message}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </div>
        </div>
      </main>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
}
